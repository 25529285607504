@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
html,
body {
  height: 100%;
}
#root {
  min-height: 100%;
}
body {
  background-color: transparent !important;
}
html {
  background-color: #050916 !important;
}

body:after {
  content: "";
  background: url(/static/media/space.373cf594.jpg) repeat-x 0 0;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: -1;
  -webkit-animation: showBackground 1s 0s forwards;
          animation: showBackground 1s 0s forwards;
}

html.light {
  background-color: #fbfdff !important;
}
html.light body:after {
  background: none;
}

@media (prefers-color-scheme: light) {
  html.light {
    background-color: #fbfdff !important;
  }
  html.light body:after {
    background: none;
  }
}

@-webkit-keyframes showBackground {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes showBackground {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

